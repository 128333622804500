.youtube-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    padding-top: 25px;
    height: 0;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }