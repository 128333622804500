body {
  background-color: #333;
}



.book-promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 10px auto;
    padding: 20px;
    background-color: #0a0909;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 767px) {
    .book-promo__content {
      flex-direction: column-reverse;
    }
  }
  
  .book-promo__content {
    display: flex;
    width: 100%;
  }
  
  .book-promo__text {
    flex: 1;
    padding: 20px;
    color:#ccc
  }
  
  .book-promo__image {
    flex: 1;
    padding: 20px;
    text-align: center;
    
  }
  
  
  .book-promo__image img {
    width: 100%;
    height: 100%;
    
  }
  

  .book-promo__form {
    width: 100%;
    padding: 20px;
    background-color: rgba(8, 7, 7, 0.8);
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 20px;
    }
    
    .book-promo__form label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color:#ccc;
    }
    
    .book-promo__form input,
    .book-promo__form textarea {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 2px solid #ccc;
    font-size: 1.2em;
    margin-left: auto;
  margin-right: auto;
    background-color: #333;
    color: #ccc;
    }
    
    .book-promo__form textarea {
    height: 100px;
    resize: none;
    }
    
    .book-promo__form button[type="submit"] {
    padding: 10px 20px;
    background-color: rgb(1, 73, 146);
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.2em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 20%;
    margin-bottom: 10px;
    align-items: right;
    margin-left: auto;
    margin-right: auto;
    }
    
    .book-promo__form button[type="button"] {
    padding: 10px 20px;
    background-color: rgb(146, 1, 1);
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    border-radius: 10px;
    font-size: 1.2em;
    cursor: pointer;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    
    }
    
    .book-promo__contact-button {
    padding: 20px 40px;
    background-color: rgb(1, 73, 146);
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.2em;
    cursor: pointer;
    margin-top: 20px;
    
    }
  
    
    
    .checkbox-and-label input[type="checkbox"] {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: white;
      border: 1px solid black;
      margin-right: 10px;
    }
    
    

   