/* Navbar.css */
nav {
    background-color: #333;
    padding: 10px;
    text-align: center;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    display: inline-block;
    margin-right: 20px;
  }
  
  a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  
  a:hover {
    color: #ffcc00;
  }
  